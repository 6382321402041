import React from "react"
import { useRef, useEffect } from 'react';
import { Bar } from "react-chartjs-2"
import {Chart as ChartJS} from 'chart.js/auto'

function BarChart() {
	
	const trafficData = useRef({
    labels: [],
    datasets: [{
      label: "Number of Hits",
      data: []
    }]
  })
  
  
  useEffect(() => {
    fetch('/api/getchartstatitics')
					.then(res => res.json())
					.then(data => JSON.parse(data))
					.then(data => {
            data.forEach((value, index) => {
              trafficData.current.labels.push(value.date.substring(0,10))
              trafficData.current.datasets[0].data.push(value.count)
            })
            console.log(trafficData.current)
						}) 
  },[]);
	return (<>
		{ trafficData.current.labels.length == 0 ? <div>no data</div> : <Bar data={trafficData.current} />}
	</>)
}

export default BarChart
