import { useState } from 'react';

const UserForm = () => {
	
	const [uname, setUname] = useState('')
	const [upass, setUpass] = useState('')
	
	const handleUserCreate = () => {
		const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: uname, password: upass})
            }
            fetch('/api/register', requestOptions)
            .then(res => res.text())
            .then(res => console.log(res))
	}
	
	return (
		<>
			<label htmlFor="uname">Username:</label><br/>
			<input type="text" id="uname" name="uname" onChange={(e)=>setUname(e.target.value)}/><br/>
			<label htmlFor="upass">Password:</label><br/>
			<input type="text" id="upass" name="upass" onChange={(e)=>setUpass(e.target.value)} /><br/><br/>
			<input type="button" value="Create" onClick={handleUserCreate} />
		</>
	)
}


export default UserForm
