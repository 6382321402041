import ReactPaginate from 'react-paginate';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
//import './Messages.css'

function Items({ currentItems }) {
  
  const handleRemoveCallBack = (index, id) => {
      console.log(index, id)
      fetch('/api/removedoc/message',{
	  method: "POST",
	  body: JSON.stringify({
	      id: id,
	    }),
	  headers: {
	      "Content-type": "application/json",
	    }
	}).then((res) => {
	    if(res.status === 200) {
	      document.getElementById(index).remove()
	      toast("Message Removed")
	    }
	    else {
	      console("Cant remove from db")
	      toast("Error: Operation Failed")
	    }
	  })
    }

  return (  
    <>
      <table>
	<thead>
	      <tr>
		<th scope="col">Name</th>
		<th scope="col">Email</th>
		<th scope="col">Mobile</th>
		<th scope="col">Message</th>
	      </tr>
	</thead>
	<tbody>
	      
	      {currentItems && currentItems.map((item, index) => (
		<tr id={index} onMouseEnter={() => {document.getElementById(item._id).style.display='inline'}} onMouseLeave={() => {document.getElementById(item._id).style.display='none'}}>
		<td data-label="Name">{item.name}</td>
		<td data-label="Email">{item.email}</td>
		<td data-label="Mobile">{item.mobile}</td>
		<td data-label="Message">{item.message}<i id={item._id} class='bx bxs-x-circle bx-tada-hover bx-sm' style={{"float":"right","color":"#FF3131","display":"none"}} onClick={() => handleRemoveCallBack(index, item._id)}></i></td>
	      </tr>
	      ))}
	</tbody>
      </table>
      <ToastContainer />
    </>
  );
}

function Messages({ itemsPerPage }) {
  // We start with an empty list of items.
  const [messages, setMessages] = useState([{}])
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
		if(messages.length === 1) {
			// Fetch items from another resources.
			fetch('/api/messages/getall')
					.then(res => res.json())
					.then(data => setMessages(JSON.parse(data)))
    }
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading message items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(messages.slice(itemOffset, endOffset));
    console.log(messages)
    setPageCount(Math.ceil(messages.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, messages]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % messages.length;
    console.log(`Messages requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };
  
  return (
    <>
		<div className="overview-boxes">
				<div>Data</div>
				<div>
					
				</div>
			</div>
      <Items currentItems={currentItems} />
      <ReactPaginate
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default Messages
