import ReactPaginate from 'react-paginate';
import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
//import './../Messages/Messages.css'
//import 'reactjs-popup/dist/index.css';
//import './Blogs.css'



function Items({ currentItems }) {
  
  const handleRemoveCallBack = (index, id) => {
      console.log(index, id)
      fetch('/api/removedoc/location',{
	  method: "POST",
	  body: JSON.stringify({
	      id: id,
	    }),
	  headers: {
	      "Content-type": "application/json",
	    }
	}).then((res) => {
	    if(res.status === 200) {
	      document.getElementById(index).remove()
	      toast("Visitor Data Removed")
	    }
	    else {
	      console("Cant remove from db")
	      toast("Error:  Operation Failed")
	    }
	  })
    }
  return (  
    <>
      <table>
	<thead>
	      <tr>
		<th scope="col">IP Address</th>
		<th scope="col">City</th>
		<th scope="col">Region</th>
		<th scope="col">Country</th>
		<th scope="col">Date Time</th>
	      </tr>
	</thead>
	<tbody>
	      
	      {currentItems && currentItems.map((item, index) => (
		<tr  id={index} onMouseEnter={() => {document.getElementById(item._id).style.display='inline'}} onMouseLeave={() => {document.getElementById(item._id).style.display='none'}}>
		<td data-label="Username">{item.data.ip}</td>
		<td data-label="Last Login">{item.data.city}</td>
		<td data-label="Action">{item.data.region_name}</td>
		<td data-label="Action">{item.data.country_name}</td>
		<td data-label="Action">{item.data.timedate}{/*item.data.timezones[0]*/}<i id={item._id} class='bx bxs-x-circle bx-tada-hover bx-sm' style={{"float":"right","color":"#FF3131","display":"none"}} onClick={() => handleRemoveCallBack(index, item._id)}></i></td>
	      </tr>
	      ))}
	</tbody>
      </table>
      <ToastContainer />
    </>
  );
}

function Visitors({ itemsPerPage }) {
  // We start with an empty list of items.
  const [visitors, setVisitors] = useState([{_id:"",data:{}}])
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
		if(visitors.length === 1) {
			// Fetch items from another resources.
			fetch('/api/visitors/getall')
					.then(res => res.json())
					.then(data => {
						setVisitors(JSON.parse(data))
						console.log(visitors)
						})
					
    }
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading user items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(visitors.slice(itemOffset, endOffset));
    console.log(visitors)
    setPageCount(Math.ceil(visitors.length / itemsPerPage));
  }, [itemOffset, itemsPerPage,visitors]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % visitors.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  return (
    <>
		<div className="overview-boxes">
				<div>Visitors DataBase</div>
			</div>
      <Items currentItems={currentItems} />
      <ReactPaginate
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<<"
        pageClassName=""
        pageLinkClassName=""
        previousClassName=""
        previousLinkClassName=""
        nextClassName=""
        nextLinkClassName=""
        breakLabel="..."
        breakClassName=""
        breakLinkClassName=""
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default Visitors
