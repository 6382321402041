import { useRef, useEffect, useState } from 'react';
import BarChart from './../Components/BarChart'
import PieChart from './../Components/PieChart'
import './DashBoard.css';

const DashBoard = () => {
  const chartData = useRef("")
  const [visitorCount, setVisitorCount] = useState(0)
  const [messageCount, setMessageCount] = useState(0)
  
  
  
  useEffect(() => {
		fetch('/api/getstatitics')
					.then(res => res.json())
					.then(data => JSON.parse(data))
					.then(data => {
            setVisitorCount(data.visitorcount)
            setMessageCount(data.messagecount)
						})
            
    /*fetch('/api/getchartstatitics')
					.then(res => res.json())
					.then(data => JSON.parse(data))
					.then(data => {
            data.forEach((value, index, array) => {
              trafficData.current.labels.push(value.date)
              trafficData.current.datasets[0].data.push(value.count)
            })
            console.log(trafficData.current.labels)
						}) */
  },[visitorCount]);
  
  
	return (
		<>
      <div className="overview-boxes">
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Visitors</div>
            <div className="number">{ visitorCount }</div>
            <div className="indicator">
              <i className='bx bx-up-arrow-alt'></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className='bx bx-user cart'></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Messages</div>
            <div className="number">{ messageCount }</div>
            <div className="indicator">
              <i className='bx bx-up-arrow-alt'></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className='bx bxs-message-dots cart two' ></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Products</div>
            <div className="number">$12,876</div>
            <div className="indicator">
              <i className='bx bx-up-arrow-alt'></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className='bx bx-box cart three' ></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Stocks</div>
            <div className="number">11,086</div>
            <div className="indicator">
              <i className='bx bx-down-arrow-alt down'></i>
              <span className="text">Down From Today</span>
            </div>
          </div>
          <i className='bx bxs-coin-stack cart four' ></i>
        </div>
      </div>

      <div className="sales-boxes">
        <div className="recent-sales box">
          <div className="title">Website Traffic<span className="span-right"><button className="button-16" title="1 Week">1W</button><button  className="button-16" title="1 Month">1M</button><button className="button-16" title="6 Month">6M</button><button  className="button-16" title="1 Year">1Y</button></span></div>
          <div className="sales-details">
            <BarChart />
          </div>
        </div>
        <div className="top-sales box">
          <div className="title">Visitors<span className="span-right"><button className="button-16" title="CITY">C</button><button  className="button-16" title="REGION">R</button><button className="button-16" title="COUNTRY">C</button></span></div>
          <PieChart />
        </div>
      </div>
      
		</>
	)
}

export default DashBoard
