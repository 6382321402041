import { useState, useEffect } from 'react'
import logo from './logo.svg';
import './App.css';
import AuthForm from './AuthForm/AuthForm'
import DashBoardRoute from './DashBoardRoute'

const App = () => {
	const [loged, setLoged] = useState(false)

    useEffect(() => {
        fetch('/checkToken')
            .then((res) => {
                if(res.status === 200) setLoged(true) // set user loged in
                else setLoged(false)
            })
            .catch((err) => {
                console.log('token not exiswts')
            })
    }, [])

    return (
        <>
            <div>
               {loged?<DashBoardRoute />:<AuthForm parentCallBack={setLoged} />}
            </div>
        </>
    )
	}

export default App;
