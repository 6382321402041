import ReactPaginate from 'react-paginate';
import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import BlogForm from './../Components/BlogForm/BlogForm'
//import './../Messages/Messages.css'
//import 'reactjs-popup/dist/index.css';
//import './Blogs.css'


const PopupNewBlog = () => (
	
  <Popup
    trigger={<button className="button1">New Blog</button>}
    modal
    nested
  >
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <div className="header"> New Blog </div>
        <div className="content">
          {' '}
			<BlogForm />
        </div>
        
      </div>
    )}
  </Popup>
);

function Items({ currentItems }) {
  return (  
      <table>
		  <thead>
			<tr>
			  <th scope="col">Blog</th>
			  <th scope="col">Editor</th>
			  <th scope="col">Action</th>
			</tr>
		  </thead>
		  <tbody>
			
			{currentItems && currentItems.map((item) => (
			  <tr>
			  <td data-label="Username">name - {item.username}</td>
			  <td data-label="Last Login">12/02/2023</td>
			  <td data-label="Action"><button className="button1">Edit</button><button>Deactivate</button><button>Delete</button></td>
			</tr>
			))}
		  </tbody>
		</table>
  );
}

function Blogs({ itemsPerPage }) {
  // We start with an empty list of items.
  const [users, setUsers] = useState([{}])
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
		if(users.length === 1) {
			// Fetch items from another resources.
			fetch('/api/blogs/getall')
					.then(res => res.json())
					.then(data => setUsers(JSON.parse(data)))
    }
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading user items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(users.slice(itemOffset, endOffset));
    console.log(users)
    setPageCount(Math.ceil(users.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, users]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % users.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  return (
    <>
		<div className="overview-boxes">
				<div>Data</div>
				<div>
					<PopupNewBlog />
				</div>
			</div>
      <Items currentItems={currentItems} />
      <ReactPaginate
        nextLabel=">>"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<<"
        pageClassName=""
        pageLinkClassName=""
        previousClassName=""
        previousLinkClassName=""
        nextClassName=""
        nextLinkClassName=""
        breakLabel="..."
        breakClassName=""
        breakLinkClassName=""
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default Blogs
