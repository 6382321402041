import { useState } from "react"
import './AuthForm.css'


const AuthForm = (props) => {
	const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    
    const handleLoginSubmit = (event) => {
        event.preventDefault()
        fetch('/api/authenticate', {
                method: 'POST',
                body: JSON.stringify({username: username, password: password}),
                headers: {
                    'Content-Type': 'application/json'
                }
              })
              .then(res => {
                if (res.status === 200) {
                    //this.props.history.push('/')
                    props.parentCallBack(true)
                    //console.log('login success')
                } else {
                    const error = new Error(res.error)
                    throw error
                }
              })
              .catch(err => {
                console.error(err)
                alert('Error logging in please try again')
            })
    }
    
    const handleSingUpSubmit = (event) => {
			event.preventDefault()
			const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username: username, password: password})
            }
            fetch('/api/register', requestOptions)
            .then(res => res.text())
            .then(res => console.log(res))
		}

		return (
			<>
        <div className="container">
          <input type="checkbox" id="check" />
          <div className="login form">
            <header>Login</header>
            <form onSubmit={ handleLoginSubmit }>
              <input type="text" placeholder="Enter your email" onChange={(e) => setUsername(e.target.value)} />
              <input type="password" placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} />
              <a href="#">Forgot password?</a>
              <input type="submit" className="button" value="Login" />
            </form>
            <div className="signup">
              <span className="signup">Don't have an account?
              <label htmlFor="check">Signup</label>
              </span>
            </div>
          </div>
          <div className="registration form">
            <header>Signup</header>
            <form onSubmit={ handleSingUpSubmit }>
              <input type="text" placeholder="Enter your email" onChange={(e) => setUsername(e.target.value)} />
              <input type="password" placeholder="Create a password" onChange={(e) => setPassword(e.target.value)} />
              <input type="password" placeholder="Confirm your password" onChange={(e) => setPassword(e.target.value)} />
              <input type="submit" className="button" value="Signup" />
            </form>
            <div className="signup">
              <span className="signup">Already have an account?
              <label htmlFor="check">Login</label>
              </span>
            </div>
          </div>
        </div>
      </>
		)
	}
export default AuthForm
