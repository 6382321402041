import { Link, Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import './DashBoardData.css'

const DashBoardData = () => {
	
	const [title, setTitle] = useState('Dashboard')
	const [username, setUsername] = useState('')
	
	useEffect(() => {
        fetch('/api/secret')
        .then(res => res.text())
        .then(res => setUsername(res))
    },[])
	
	const menu = [
			{title: "Dashboard", icon: "bx bx-grid-alt", link: "/"},
			{title: "User", icon: "bx bx-user", link: "/user"},
			{title: "Visitors", icon: "bx bxs-user-detail", link: "/visitors"},
			{title: "Messages", icon: "bx bx-chat", link: "/messages"},
			{title: "Blogs", icon: "bx bxl-blogger", link: "/blogs"},
			{title: "Services", icon: "bx bx-coin-stack", link: "/services"},
			{title: "Settings", icon: "bx bx-cog", link: "/settings"}	
		]
	
	const closeBtnClick = () => {
		let sidebar = document.querySelector(".sidebar")
		let sidebarBtn = document.querySelector(".sidebarBtn")
		sidebar.classList.toggle("active");
		  if(sidebar.classList.contains("active")){
		  sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
		}else
		  sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
	}
	

	const handleSignOut = (event) => {
        event.preventDefault()
        fetch('/api/logout')
        .then(res => {
            if(res.status === 200) {
                //props.logoutCallBack(false)
                //navi.navigate(0)
                //useHistory().push('/')
		//window.location.pathname = '/'
		window.location.replace('/')
                //window.location.reload()
            }
        }).catch(err => console.log(err))
    }
	
	return (
		<>
		<link href='https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css' rel='stylesheet'  />
			<div className="sidebar">
    <div className="logo-details">
      <i className='bx bxl-c-plus-plus'></i>
      <span className="logo_name">Live Zone</span>
    </div>
      <ul className="nav-links">
		{ menu.map((item) => <li key={item.title}>
			<span href="#" onClick={(e)=>{e.preventDefault();setTitle(item.title);}}>
            <Link to={item.link}>
            <i className={item.icon} ></i>
            <span className="links_name">{item.title}</span>
            </Link>
          </span>
          </li>)
          }
        <li className="log_out">
          <a href="#" onClick={(e)=>{e.preventDefault();handleSignOut(e);}}>
            <i className='bx bx-log-out'></i>
            <span className="links_name">Log out</span>
          </a>
        </li>
      </ul>
  </div>
  <section className="home-section">
    <nav>
      <div className="sidebar-button">
        <i className='bx bx-menu sidebarBtn' onClick={closeBtnClick}></i>
        <span className="dashboard">{title}</span>
      </div>
      <div className="search-box">
        <input type="text" placeholder="Search..." />
        <i className='bx bx-search' ></i>
      </div>
      <div className="profile-details">
        <i className='bx bx-user-circle'></i>
        <span className="admin_name">{ username }</span>
        <i className='bx bx-chevron-down' ></i>
      </div>
    </nav>
    <div className="home-content">
		<Outlet />
    </div>
    
  </section>
		</>
	)
}
export default DashBoardData
