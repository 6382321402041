import { Routes, Route } from 'react-router-dom'
import DashBoardData from './DashBoardData/DashBoardData'
import DashBoard from './DashBoardData/DashBoard/DashBoard'
import User from './DashBoardData/User/User'
import Messages from './DashBoardData/Messages/Messages'
import Blogs from './DashBoardData/Blogs/Blogs'
import Services from './DashBoardData/Services/Services'
import Settings from './DashBoardData/Settings/Settings'
import ErrorPage from './DashBoardData/Error/Error';
import Visitors from './DashBoardData/Visitors/Visitors'

const DashBoardRoute = () => {
	
	return (
			<>
			  <Routes>
				<Route path='/' element={<DashBoardData />} >
				  <Route index element={<DashBoard />} />
				  <Route path='user' element={<User itemsPerPage={7} />} />
				  <Route path='visitors' element={<Visitors itemsPerPage={7} />} />
				  <Route path='messages' element={<Messages itemsPerPage={7} />} />
				  <Route path='blogs' element={<Blogs />} />
				  <Route path='services' element={<Services />} />
				  <Route path='settings' element={<Settings />} />
				  <Route path='*' element={<ErrorPage />} />
				 </Route>
			  </Routes>
			 </>
	)
}

export default DashBoardRoute

