import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogForm = () => {
	const [value, setValue] = useState('');

	const handleSubmit = () => {
		console.log(value)
	}
	
	return (
		<>
		  <ReactQuill theme="snow" value={value} onChange={setValue} />;
		  <button onClick={ handleSubmit }>Submit</button>
		</>
	)
}


export default BlogForm
