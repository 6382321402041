import { useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Settings = () => {
	const mobileRef = useRef()
	const emailRef = useRef()
	const videoUrlRef = useRef()
	const locationRef = useRef()
	
	const twRef = useRef()
	const faRef = useRef()
	const inRef = useRef()
	const liRef = useRef()
	
	useEffect(() => {
		fetch('/api/getwebdata')
					.then(res => res.json())
					.then(data => JSON.parse(data))
					.then(data => {
						mobileRef.current.value = data[0].mobile
						emailRef.current.value = data[0].email
						videoUrlRef.current.value = data[0].videourl
						locationRef.current.value = data[0].location
						twRef.current.value = data[0].tw
						faRef.current.value = data[0].fa
						inRef.current.value = data[0].in
						liRef.current.value = data[0].li
						console.log(data)
						})
  }, []);
	
	function applyed() {
		console.log(locationRef.current.value)
			fetch("/api/setwebdata", {method: "POST", body: JSON.stringify({
				mobile: mobileRef.current.value, 
				email: emailRef.current.value, 
				videourl: videoUrlRef.current.value,
				location: locationRef.current.value,
				tw: twRef.current.value,
				fa: faRef.current.value,
				inst: inRef.current.value,
				li: liRef.current.value}),
				headers: {
					"Content-type": "application/json; charset=UTF-8"
					}
					}).then((res) => {
						if(res.status === 200) {
							console.log("Oepration Success")
						  toast("Oepration Success")
						}
						else {
						  console.log("Error: Operation Failed")
						  toast("Error:  Operation Failed")
						}
						})
		}
	
	return (
		<>
			<table>
				<tr>
					<td className="customTableTd">Video</td>
					<td><input ref={videoUrlRef} type="text" placeholder="video link (YouTube)..." className="input-res" /><br /><input type="file" placeholder="video thumpnail" className="input-res"  /></td>
				</tr>
				<tr>
					<td>Contact</td>
					<td><input ref={mobileRef} type="text" id="data_mobile" placeholder="mobile" className="input-res"  /><br /><input ref={emailRef} type="text" id="data_email" placeholder="email" className="input-res"  /><br /><textarea ref={locationRef} id="data_location" placeholder="location address.." className="input-res" /></td>
				</tr>
				<tr>
					<td>Social Media Links</td>
					<td>
					<input ref={twRef} type="text" placeholder="twitter..." className="input-res" /><br />
					<input ref={faRef} type="text" placeholder="facebook..."  className="input-res" /><br />
					<input ref={inRef} type="text" placeholder="instagram..."  className="input-res" /><br />
					<input ref={liRef} type="text" placeholder="linkedin..."  className="input-res" /><br />
					</td>
				</tr>
			</table>
			<nav style={{"bottom": "0px"}}>
				<button className="button1" onClick={applyed}>Apply</button>
			</nav>
			<ToastContainer />
			</>
	)
}

export default Settings
